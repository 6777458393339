<template>
  <v-container fluid>
    <v-card
      elevation="4"
      class="mt-0"
    >
      <v-tabs
        v-model="tab"
        @change="tabChanged(tab)"
      >
        <v-tab>
          {{ $t("config") }}
        </v-tab>
        <v-tab>
          <v-badge
            v-if="criticalStockBadge.count"
            :content="criticalStockBadge.count"
            right
          >
            {{ $t("stock") }}
          </v-badge>
          <template v-else>
            {{ $t("stock") }}
          </template>
        </v-tab>
        <v-tab>
          <v-badge
            v-if="criticalExpiryBadge.count"
            :content="criticalExpiryBadge.count"
            right
          >
            {{ $t("expiry") }}
          </v-badge>
          <template v-else>
            {{ $t("expiry") }}
          </template>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <config-tab
            :isActive="items[tab]?.content == 'config'"
            v-if="item.content == 'config'"
          ></config-tab>
          <stock-alerts-tab
            :isActive="items[tab]?.content == 'stock'"
            v-if="item.content == 'stock'"
          ></stock-alerts-tab>
          <expiry-alerts-tab
            :isActive="items[tab]?.content == 'expiry'"
            v-if="item.content == 'expiry'"
          ></expiry-alerts-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    ConfigTab: () => import("./alerts/ConfigTab.vue"),
    StockAlertsTab: () => import("./alerts/StockAlertsTab.vue"),
    ExpiryAlertsTab: () => import("./alerts/ExpiryAlertsTab.vue"),
  },

  async mounted() {
    await this.$store.dispatch("productAlerts/setParams", {});
    await this.$store.dispatch("badges/getProductsAlert", "stock");
    await this.$store.dispatch("badges/getProductsAlert", "expiry");
  },

  computed: {
    ...mapGetters({
      productsParams: "productAlerts/params",
      criticalStockBadge: "badges/getProductsStockAlert",
      criticalExpiryBadge: "badges/getProductsExpiryAlert",
    }),
  },

  methods: {
    async tabChanged(index) {
      try {
        await this.$store.dispatch("productAlerts/clean");
        await this.$store.dispatch("productAlerts/setParams", {
          alert: this.items[index].status,
          itemsPerPage: 30,
          page: 1,
        });
        await this.$store.dispatch("badges/getProductsAlert", "stock");
        await this.$store.dispatch("badges/getProductsAlert", "expiry");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },

  data() {
    return {
      tab: null,
      items: [
        {
          tab: this.$t("config"),
          status: null,
          content: "config",
        },
        {
          tab: this.$t("stock"),
          status: "stock",
          content: "stock",
        },
        {
          tab: this.$t("expiry"),
          status: "expiry",
          content: "expiry",
        },
      ],
    };
  },
};
</script>
